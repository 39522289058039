
// Body
$body-bg: white;

// Brands
$brand-primary: #0A0A0A;
$brand-info: #8eb4cb;
$brand-success: #2ab27b;
$brand-warning: #cbb956;
$brand-danger:  #bf5329;

// Typography
$font-family-sans-serif:  "Helvetica Neue", Helvetica, Arial, sans-serif;
$font-family-serif:       "Akzidenz", sans-serif;
$font-size-base: 12px;
$line-height-base: 1.6;
$text-color: #5b5b5b;

$font-size-h1:  floor(($font-size-base * 3));
$font-size-h2:  floor(($font-size-base * 2.15));
$font-size-h3:  ceil(($font-size-base * 1.7));
$font-size-h4:  ceil(($font-size-base * 1.25));
$font-size-h5:  $font-size-base;
$font-size-h6:  ceil(($font-size-base * 0.85));

// Borders
$laravel-border-color: darken($body-bg, 10%);
$list-group-border: $laravel-border-color;
$navbar-default-border: $laravel-border-color;
$panel-default-border: $laravel-border-color;
$panel-inner-border: $laravel-border-color;

// Headings
$headings-font-family:    inherit;
$headings-font-weight:    500;
$headings-line-height:    1.1;
$headings-color:          $brand-primary;

// Navbar
$navbar-default-bg: #fff;

// Borders
$border-radius-small:   0px;
$border-radius-base:    0px;
$border-radius-large:   2px;

// Buttons
$btn-default-color: $text-color;

// Inputs
$label-color: $brand-primary;
$input-border: #999;
$input-border-focus: lighten($brand-primary, 25%);
$input-color-placeholder: lighten($text-color, 30%);

// Panels
$panel-default-heading-bg: #fff;
