@charset "utf-8";

@import "fonts";
@import "variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@import "node_modules/sweetalert/dev/sweetalert";
@import "node_modules/sweetalert/themes/google/google.scss";
@import "general";

.image-background {
  background: no-repeat center;
  background-size: cover;
  width: 100%;
}

.image-center {
  height: 400px;
  margin-top: 40px;
  margin-bottom: 40px;
}

.image-center .image-double {
  width: 50%;
  height: 100%;
  float: left;
}

.img-long {
  margin-top: 0;
}

.image-400 {
  height: 400px;
}

.image-500 {
  height: 500px;
}

.background-position {
  background-position: top;
}

.intro {
  background-attachment: fixed;
  height: 100vh;

  h1 {
    font-size: 50px;
    margin-top: 25%;
    color: white;
    text-shadow: 0 2px 4px rgba(0,0,0,0.5);
    font-weight: 300;
    letter-spacing: 5px;
    font-family: choco;

    @media screen and (max-width: 480px) {
      font-size: 35px;
    }
  }
}

.content-row {
  margin-bottom: 40px;
  margin-top: 40px;
}

.title-one {
  height: 200px;

  div {
    height: 200px;
    width: 250px;
    margin-top: 25px;
  }
}

.information-general p {
  color: #5b5b5b;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 1px;
  font-family: choco;
}

.second-section p {
  margin-top: 80px;
  padding-right: 60px;
}


input,
textarea {
  outline: none;
  background-color: #fff;
  font-size: 12px;
  appearance: none;
  font-family: Helvetica, Arial, sans-serif;
  font-weight: normal;
}