* {
  margin: 0;
  padding: 0;
  font-family: choco;
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-family-sans-serif;
}

h1 {
  text-transform: uppercase;
}

p {
  font-family: choco;
}
